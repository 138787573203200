import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import axios from "axios"
import { useParams, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Swal from "sweetalert2"

const Register = props => {
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({})

  const { cin } = useParams()

  const [natureLicence, setNatureLicence] = useState([])
  const [session, setSession] = useState([
    { value: 1, label: "Principale" },
    { value: 2, label: "Contrôle" },
  ])
  const [mastere, setMastere] = useState([])
  const [matieres, setMatieres] = useState([])
  const [etablissement, setEtablissement] = useState([])
  const currentYear = new Date().getFullYear()
  const [inscription, setInscription] = useState([])
  const yearOptions = []

  for (let i = currentYear; i >= 1900; i--) {
    yearOptions.push({ value: i, label: i })
  }

  useEffect(async () => {
    const res = await axios
      .get("https://scolarite.backcresus-institut.ovh/api/type/master/select")
      .then(res => {
        setMastere(res.data.TypeMaster)
      })
    const resN = await axios
      .get(
        "https://scolarite.backcresus-institut.ovh/api/nature/license/select"
      )
      .then(resN => {
        setNatureLicence(resN.data.NatureLicense)
      })
    const result = await axios
      .get(
        "https://scolarite.backcresus-institut.ovh/api/origine/etablissement/select"
      )
      .then(result => {
        setEtablissement(result.data.OrigineEtablissement)
      })
    if (cin != "00000000") {
      const result = await axios
        .post(
          "https://scolarite.backcresus-institut.ovh/api/inscription/master/get_by_cin",
          { cin }
        )
        .then(result => {
          setInscription(result.data.InscriptionMaster)
          setMatieres(result.data.InscriptionMaster.matiere)
        })
    }
  }, [])

  const getMatiere = async event => {
    setValue("mastere", event)
    const res = await axios
      .post(
        "https://scolarite.backcresus-institut.ovh/api/type/master/get_matiere",
        { mastere_id: event.value }
      )
      .then(res => {
        setMatieres(res.data.matieres)
      })
  }

  const addMatiere = (event, index) => {
    if (event == 1) {
      setMatieres(
        matieres.map((el, id) =>
          id === index ? Object.assign(el, { etat: event }) : el
        )
      )
    } else {
      setMatieres(
        matieres.map((el, id) =>
          id === index ? Object.assign(el, { etat: event }) : el
        )
      )
    }
  }

  const onSubmit = async data => {
    const res = await axios
      .post(
        "https://scolarite.backcresus-institut.ovh/api/inscription/master/add",
        {
          data: data,
          matieres: matieres,
        }
      )
      .then(res => {
        Swal.fire("Good job!", "success")
        props.history.push("/login")
      })
  }

  return (
    <React.Fragment>
      <div className="mt-3">
        <MetaTags>
          <title>Inscription |</title>
        </MetaTags>
        <Container fluid>
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                  Informations personnelles :
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Nom :</Label>
                      <input
                        name="Nom"
                        placeholder="Entrer Nom"
                        type="text"
                        value={inscription.nom}
                        className="form-control"
                        {...register("nom", {
                          required: true,
                        })}
                      />
                      {errors.nom && (
                        <span style={{ color: "red" }}>required Nom</span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Prénom :</Label>
                      <input
                        name="Prénom"
                        placeholder="Entrer Prénom"
                        type="text"
                        value={inscription.prenom}
                        className="form-control"
                        {...register("prenom", {
                          required: true,
                        })}
                      />
                      {errors.prenom && (
                        <span style={{ color: "red" }}>required Prénom</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">CIN :</Label>
                      <input
                        name="CIN"
                        placeholder="Entrer CIN"
                        type="number"
                        value={inscription.cin}
                        className="form-control"
                        {...register("cin", {
                          required: "CIN is required",
                          minLength: 8,
                          maxLength: 8,
                          message: "Invalid cin",
                        })}
                      />
                      {errors.cin && (
                        <span style={{ color: "red" }}>required CIN</span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">E-mail :</Label>
                      <input
                        name="Email"
                        placeholder="Entrer Email"
                        type="email"
                        value={inscription.email}
                        className="form-control"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span style={{ color: "red" }}>
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Numéro téléphone :
                      </Label>
                      <input
                        name="Numéro téléphone"
                        placeholder="Entrer Numéro téléphone"
                        type="number"
                        value={inscription.telephone}
                        className="form-control"
                        {...register("numTel", {
                          required: true,
                        })}
                      />
                      {errors.numTel && (
                        <span style={{ color: "red" }}>
                          required numéro de téléphone
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                  Diplômes obtenus et parcours universitaire :
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Année d’obtention du diplôme de baccalauréat :"}
                      </Label>
                      <Controller
                        name="anneeBac"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={yearOptions}
                            value={inscription.anneBac}
                            isSerchable
                          />
                        )}
                      />
                      {errors.anneeBac && (
                        <span style={{ color: "red" }}>
                          required année du diplôme de baccalauréat
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Nature de la licence :
                      </Label>
                      <Controller
                        name="natureLicence"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={natureLicence}
                            value={inscription.NatureLicence}
                            isSerchable
                          />
                        )}
                      />
                      {errors.natureLicence && (
                        <span style={{ color: "red" }}>
                          required nature de la licence
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Etablissement d’origine :"}
                      </Label>
                      <Controller
                        name="etablissement"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={etablissement}
                            value={inscription.origine_etablissement}
                            isSerchable
                          />
                        )}
                      />
                      {errors.etablissement && (
                        <span style={{ color: "red" }}>
                          required etablissement
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Année d’obtention du diplôme de licence :"}
                      </Label>
                      <Controller
                        name="anneeDiplome"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={yearOptions}
                            value={inscription.anneLicence}
                            isSerchable
                          />
                        )}
                      />
                      {errors.anneeDiplome && (
                        <span style={{ color: "red" }}>
                          required année du diplôme de licence
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                  Eléments du calcul de score :
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Moyenne générale de la première année :
                      </Label>
                      <input
                        name="moy1"
                        placeholder="Entrer moyenne"
                        type="text"
                        value={inscription.moyennePremiere}
                        className="form-control"
                        {...register("moy1", {
                          required: true,
                          min: 0,
                          max: 20,
                        })}
                      />
                      {errors.moy1 && (
                        <span style={{ color: "red" }}>
                          Moyenne obligatoire et la note doit étre entre 0 et 20
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Session d’admission :"}
                      </Label>
                      <Controller
                        name="session1"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={session}
                            value={inscription.sessionAdmissionPremiere}
                            isSerchable
                          />
                        )}
                      />
                      {errors.session1 && (
                        <span style={{ color: "red" }}>required session</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Moyenne générale de la deuxième année :
                      </Label>
                      <input
                        name="moy2"
                        placeholder="Entrer moyenne"
                        type="text"
                        value={inscription.moyenneDeuxieme}
                        className="form-control"
                        {...register("moy2", {
                          required: true,
                          min: 0,
                          max: 20,
                        })}
                      />
                      {errors.moy2 && (
                        <span style={{ color: "red" }}>
                          Moyenne obligatoire et la note doit étre entre 0 et 20
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Session d’admission :"}
                      </Label>
                      <Controller
                        name="session2"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={session}
                            value={inscription.sessionAdmissionDeuxieme}
                            isSerchable
                          />
                        )}
                      />
                      {errors.session2 && (
                        <span style={{ color: "red" }}>required session</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Moyenne générale de la troisième année :
                      </Label>
                      <input
                        name="moy3"
                        placeholder="Entrer moyenne"
                        type="text"
                        value={inscription.moyenneTroisieme}
                        className="form-control"
                        {...register("moy3", {
                          required: true,
                          min: 0,
                          max: 20,
                        })}
                      />
                      {errors.moy3 && (
                        <span style={{ color: "red" }}>
                          Moyenne obligatoire et la note doit étre entre 0 et 20
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Session d’admission :"}
                      </Label>
                      <Controller
                        name="session3"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={session}
                            value={inscription.sessionAdmissionTroisieme}
                            isSerchable
                          />
                        )}
                      />
                      {errors.session3 && (
                        <span style={{ color: "red" }}>required session</span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Note du projet de fin d’études (PFE) :"}
                      </Label>
                      <p style={{ color: "red" }}>
                        {
                          " NB : Uniquement les candidats titulaires d'une licence appliquée sont appelés à saisir la note du PFE. Pour lesautres candidats (licences fondamentales et nationales) veuillez saisir zéro et votre score ne sera pas affecté."
                        }
                      </p>
                      <input
                        name="notePfe"
                        placeholder="Entrer note PFE"
                        type="text"
                        value={inscription.notePfe}
                        className="form-control"
                        {...register("notePfe", {
                          required: true,
                          min: 0,
                          max: 20,
                        })}
                      />
                      {errors.notePfe && (
                        <span style={{ color: "red" }}>
                          Note (PFE) obligatoire et la note doit étre entre 0 et
                          20
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Nombre d'années de redoublement :"}
                      </Label>
                      <input
                        name="anneeRedoublant"
                        placeholder="Entrer Nombre d'années de redoublement"
                        type="number"
                        value={inscription.nbAnneRedoublement}
                        className="form-control"
                        {...register("anneeRedoublant", {
                          required: true,
                          min: 0,
                        })}
                      />
                      {errors.anneeRedoublant && (
                        <span style={{ color: "red" }}>
                          required Nombre années de redoublement
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        {"Nombre d'années blanches : :"}
                      </Label>
                      <input
                        name="anneeBlanche"
                        placeholder="Entrer Nombre d'années blanches"
                        type="number"
                        value={inscription.nbAnneBlanches}
                        className="form-control"
                        {...register("anneeBlanche", {
                          required: true,
                          min: 0,
                        })}
                      />
                      {errors.anneeBlanche && (
                        <span style={{ color: "red" }}>
                          required Nombre années blanches
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                  Choix de mastères :
                </CardTitle>
                <Row>
                  <p style={{ color: "red" }}>
                    {
                      " NB : Le candidat sera présélectionné que lorsqu’il a suivi au moins 3 matières fondamentales."
                    }
                  </p>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Mastère :</Label>
                      <Controller
                        name="mastere"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={mastere}
                            isSerchable
                            value={inscription.type_master_id}
                            onChange={e => getMatiere(e)}
                          />
                        )}
                      />
                      {errors.mastere && (
                        <span style={{ color: "red" }}>
                          required choix du mastère
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <CardTitle className="h4 mb-4">
                    Matières fondamentales :
                  </CardTitle>
                  {matieres &&
                    matieres.map((element, index) => (
                      <div key={index}>
                        <p className="mt-3">{element.nom}</p>
                        <Col md="6">
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id={"customRadioInline" + index}
                              name={"customRadioInline" + index}
                              className="form-check-input"
                              onClick={e => addMatiere(1, index)}
                              checked={element.etat === 1}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={"customRadioInline" + index}
                            >
                              Oui
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id={"customRadioInline" + index}
                              name={"customRadioInline" + index}
                              className="form-check-input"
                              onClick={e => addMatiere(0, index)}
                              checked={element.etat === 0}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={"customRadioInline" + index}
                            >
                              Non
                            </Label>
                          </div>
                        </Col>
                      </div>
                    ))}
                </Row>
              </CardBody>
            </Card>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {cin != "0000000" ? (
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning  mb-2 me-2"
                    >
                      IMPRIMER
                    </Button>
                  </div>
                </Col>
              ) : (
                <Col lg="6">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning  mb-2 me-2"
                    >
                      ANNULER
                    </Button>
                  </div>
                </Col>
              )}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <Button type="submit" className="btn btn-primary mb-2 me-2">
                    CONFIRMER
                  </Button>
                </div>
              </Col>
            </div>
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Register)
Register.propTypes = {
  history: PropTypes.object,
}
