import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"

class Dashboard extends Component {
  componentDidMount() {
    
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Inscription Mastère | Cresus</title>
          </MetaTags>
          <Container fluid>
           Ahmeed
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard
