import React, { useEffect, useState } from "react"
import { CardBody, Col, Container, Label, Row } from "reactstrap"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import axios from "axios"

const Login = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})
  const [active, setActive] = useState("")

  useEffect(async () => {
    const res = await axios
      .get("https://scolarite.backcresus-institut.ovh/api/master/activate/get")
      .then(res => {
        if (res.data.ActivationMastere.etat === 1) {
          setActive(true)
        } else {
          setActive(false)
        }
      })
  }, [])

  const inscrit = async () => {
    props.history.push("/Register/00000000")
  }

  const onSubmit = async data => {
    props.history.push("/Register/" + data.cin)
  }

  return (
    <div
      style={{ paddingTop: "13%", backgroundColor: "#f5eef2", height: "100vh" }}
    >
      <Container>
        <Row className="justify-content-center">
          <div className="col-md-8 col-lg-m col-xl-5 no-padd">
            <div
              className="overflow-hidden"
              style={{ backgroundColor: "#fff", height: "400px" }}
            >
              <Row>
                <Col className="justify-content-center pt-3">
                  <h1
                    style={{
                      color: "#2eadd1",
                      textAlign: "center",
                      fontFamily: " Lato,Tajawal,sans-serif !important",
                    }}
                  >
                    Inscription Mastère
                  </h1>
                </Col>
              </Row>
              <CardBody className="pt-4">
                {active === true ? (
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="p-2">
                      <div className="mt-3 mb-3">
                        <Label for="basicpill-firstname-input1">CIN :</Label>
                        <input
                          name="cin"
                          placeholder="Entrer CIN"
                          type="text"
                          className="form-control"
                          {...register("cin", {
                            required: true,
                            minLength: 8,
                            maxLength: 8,
                          })}
                        />
                        {errors.cin && (
                          <span style={{ color: "red" }}>required CIN</span>
                        )}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Déja inscrit
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={inscrit}
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          Inscription
                        </button>
                      </div>
                    </div>
                  </form>
                ) : null}
              </CardBody>
              <div className="mt-4 text-center">
                <p>
                  © {new Date().getFullYear()} Cresus. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a href="https://cresus.pro/"> cresus.pro</a>
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
